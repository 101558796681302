@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

ion-select#conditional-select.select-disabled {
  background: unset;
  opacity: unset;
  font-weight: unset;
  cursor: none;
  padding-top: unset;
  border: none !important;
}

ion-select#conditional-select.select-disabled::part(icon) {
  all: unset;
  visibility: hidden;
}

.action-sheet-container.sc-ion-action-sheet-ios {
  justify-content: center;
  padding-top: 20%;
  padding-bottom: 20%;
}

.action-sheet-group.sc-ion-action-sheet-ios::-webkit-scrollbar {
  display: block;
  background: var(--scrollbar-background-color);
  border-radius: inherit;
}

.action-sheet-group.sc-ion-action-sheet-ios::-webkit-scrollbar-thumb {
  background: var(--scrollbar-color);
  border-radius: inherit;
}

.action-sheet-group.sc-ion-action-sheet-ios::-webkit-scrollbar-button {
  background: var(--scrollbar-background-color);
  border-style: solid;
  border-width: 8px 8px 8px 8px;
  height: 8px;
  border-radius: inherit;
}

.action-sheet-group.sc-ion-action-sheet-ios::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-color: transparent transparent var(--scrollbar-button-color) transparent; 
}

.action-sheet-group.sc-ion-action-sheet-ios::-webkit-scrollbar-button:single-button:vertical:increment {
  border-color: var(--scrollbar-button-color) transparent transparent transparent; 
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-weight: 700;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

p {
  font-weight: 400;
  color: #8c8c8c;
}

ul,
ol {
  margin-bottom: 0.67em;
}

li {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 0.67em;
  color: #8c8c8c;
}

.font-w-600 {
  font-weight: 600;
}

.container {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
  overflow-y: auto;
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
}

.container a {
  text-decoration: none;
}

ion-item {
  font-size: 14px;
  line-height: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.white-bg {
  background: #fff;
}

.lt-gr-bg {
  background: #f6f6f6;
}

.primary-bg {
  background: var(--ion-color-primary);
}

.secondary-bg {
  background: var(--ion-color-secondary);
}

.white-color {
  color: #fff !important;
}

.black-color {
  color: #000 !important;
}

.grey-color {
  color: grey;
}

.select-disabled {
  background: #545454;
  opacity: 30%;
  --placeholder-opacity: 1 !important;
  font-weight: bold;
  cursor: none;
}

.gr-border {
  border: solid #e4e4e4 3px !important;
}

.gr-border-thin {
  border: solid #e4e4e4 1px !important;
}

.gr-border-circle {
  border: solid #e4e4e4 3px !important;
  border-radius: 50px;
}

.light-gr-bg {
  background: #eee;
}

.no-border {
  border: none !important;
}

.w-100 {
  width: 100% !important;
}

.cancel-modal {
  --height: 160px;
  --width: 310px;
}

.modal-wrapper.sc-ion-modal-md {
  height: 400px !important;
  top: 30% !important;
}

.lg-checkbox {
  width: 18px;
  height: 18px;
}

a:link,
a:visited,
a:focus {
  color: #000;
  text-decoration: underline;
}

.billing-item {
  --ion-safe-area-right: 0;
}

.billing-memo {
  color: #000 !important;
}

.negative-amount {
  color: #000 !important;
  font-weight: 600;
  border-left: 1px solid #c8c7cc;
  padding-left: 20px;
}

.positive-amount {
  color: #009425 !important;
  font-weight: 600;
  border-left: 1px solid #c8c7cc;
  padding-left: 20px;
}

.billing-date {
  font-size: 15px;
  font-weight: bold;
  display: inline-block;
  color: #8c8c8c;
  padding-top: 8px;
}

.hidden {
  display: none;
}

.divider-top {
  border-top: solid 1px #e4e4e4;
}

.divider {
  border-bottom: solid 1px #e4e4e4;
}

.date-width {
  width: 100% !important;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--ion-color-primary) !important;
}

.MuiPickersDay-daySelected {
  background-color: var(--ion-color-primary) !important;
}

.MuiButton-label {
  color: var(--ion-color-secondary) !important;
}

.MuiTextField-root {
  border-color: black;
}

ion-checkbox {
  position: relative;
}

.header-md::after {
  background-image: none;
}

.bottom-text {
  font-size: 20px !important;
}

.lined-text {
  width: 100%;
  text-align: center;
  border-bottom: 4px solid #e4e4e4;
  line-height: 0.1em;
  margin: 10px 0 20px;
  color: #434343;
}

.lined-text span {
  background: #fff;
  padding: 0 10px;
}

.underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.remove-focus {
  outline: none !important;
  border: none !important;
}

.active-tab {
  border-bottom: #002f5f solid 4px;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-end {
  text-align: end !important;
}
.text-center {
  text-align: center !important;
}

.h-100 {
  height: 100% !important;
}

.visibility-unset{
  visibility: unset !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-self-end {
  align-self: flex-end;
}

.justify-end {
  justify-content: end !important;
}

.justify-right {
  justify-content: right !important;
}

.justify-center {
  justify-content: center;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.table-btn {
  position: absolute;
  right: 0;
}

.forms-table {
  border: solid 1px #eee;
  padding: 0 10px;
}

.table-header {
  border: solid 1px #eee;
  padding: 0 10px;
}

.table-header p {
  margin: 10px 0;
}

.success {
  color: #2dd36f;
}

.m-0 {
  margin: 0;
}


.m-1 {
  margin: 10px;
}

.m-2 {
  margin: 20px;
}

.m-3 {
  margin: 30px;
}

.m-4 {
  margin: 40px;
}

.m-5 {
  margin: 50px;
}

.ml-0 {
  margin-left: 0;
}
.ml-0-5{
  margin-left: 5px !important;
 }

.ml-1 {
  margin-left: 10px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-3 {
  margin-left: 30px;
}

.ml-4 {
  margin-left: 40px;
}

.ml-5 {
  margin-left: 50px;
}

.mt-0 {
  margin-top: 0;
}

.mt-0-5 {
 margin-top: 5px !important;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mt-4 {
  margin-top: 40px;
}

.mt-5 {
  margin-top: 50px;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 10px;
}

.mr-1-5 {
  margin-right: 15px;
}

.mr-2 {
  margin-right: 20px;
}

.mr-3 {
  margin-right: 30px;
}

.mr-4 {
  margin-right: 40px;
}

.mr-5 {
  margin-right: 50px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0-5 {
  margin-bottom: 5px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-1-4 {
  margin-bottom: 14px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 10px;
}

.p-2 {
  padding: 20px;
}

.p-3 {
  padding: 30px;
}

.p-4 {
  padding: 40px;
}

.p-5 {
  padding: 50px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 10px !important;
}

.pl-2 {
  padding-left: 20px;
}

.pl-3 {
  padding-left: 30px;
}

.pl-4 {
  padding-left: 40px;
}

.pl-5 {
  padding-left: 50px;
}

.pt-0 {
  padding-top: 0;
}

.pt-0-5 {
  padding-top: 5px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

.pr-0 {
  padding-right: 0;
}

.pr-1 {
  padding-right: 10px !important;
}

.pr-2 {
  padding-right: 20px;
}

.pr-3 {
  padding-right: 30px;
}

.pr-4 {
  padding-right: 40px;
}

.pr-5 {
  padding-right: 50px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

.last-four {
  font-size: 25px;
}

.m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.login-box {
  max-width: 600px;
  display: block;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.tooltip {
  color: #002f5f;
  font-size: 22px;
  vertical-align: middle;
}

.tooltip:hover {
  color: #f06d1a;
  cursor: pointer;
}

.tooltip-danger {
  color: #002f5f;
  font-size: 22px;
  vertical-align: top;
}

.tooltip-danger:hover {
  color: #fff;
  cursor: pointer;
}

.tooltip-box {
  color: #fff;
  background: #002f5f;
  padding: 3px 6px;
  position: relative;
  z-index: 1000;
  width: 100%;
}

.tooltip-aligned-left {
  font-size: 14px;
  text-align: left;
  display: block;
  margin: 0;
  color: #8c8c8c;
}

.feedback-modal .modal-wrapper {
  min-height: 660px!important;
}

.transaction-info-modal .modal-wrapper {
  max-height: 300px;
}

.react-autosuggest__container {
  position: relative;
  width: 100%;
}

.react-autosuggest__input {
  width: 100%;
  height: 3rem;
  padding: 10px 20px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container--open {
  display: black;
  position: relative;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.inherit-background {
  background-color: inherit;
}

.react-autosuggest__suggestion-match {
  color: var(--ion-color-primary);
  font-weight: bold;
}

.spacer {
  border-top: 8px solid white;
}

.text-branded-blue {
  color: #002f5f !important;
}

.text-branded-orange {
  color: #f06d1a !important;
}

.text-danger {
  color: #e04055;
}

.page-title {
  font-size: 28px;
  padding: 0 20px;
  color: #002f5f;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 0;
}

.page-numbers {
  float: left;
}

.pagination-btns {
  margin: auto;
}

.infinite-loading {
  display: block !important;
}

.noshadow {
  --border-width: 0 !important;
}

.nozindex {
  z-index: 0 !important;
}

.nav-item {
  font-size: 12px !important;
}

.hamburger {
  font-size: 40px;
  color: #002f5f;
}

.error {
  background: #eb445a;
  color: #fff;
  padding: 10px 0;
}

.input-mask {
  height: 50px;
}

ion-select {
  min-width: 100%;
  padding-left: 0;
}

.agree-text {
  white-space: normal !important;
}

.mobile-header {
  background: #002f5f;
}

.mobile-header-toolbar {
  display: flex;
  align-items: center;
  height: 75px;
}

.logo-mobile {
  height: 45px;
  width: auto;
}

ion-header {
  border-bottom: #ccc solid 3px;
}

.bar-header {
  border: 0px !important;
  border-bottom-color: transparent !important;
  background-image: none !important;
  border-bottom: none !important;
}

.alert-wrapper.sc-ion-alert-md {
  max-width: 800px !important;
  width: 100% !important;
  margin: auto 40px;
}

/* Header Styling */
.header-shadow {
  box-shadow: 0px 10px 10px #ccc;
}

bottom-border {
  border-bottom: solid 2px #ccc;
}

.header-row {
  max-width: 1380px;
  margin: auto auto;
  padding-left: 10px;
  padding-right: 10px;
}

.header-text-center {
  margin: 25px 0 25px 0;
}

.header-title-div {
  display: inline-block;
  width: 80%;
  position: relative;
  bottom: 15%;
  left: 2%;
}

.toggle-icon {
  position: absolute;
  top: calc(50% - 10px);
  left: 60%;
}

.hover-cursor {
  cursor: pointer;
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.rating {
  max-width: 70px;
  cursor: pointer;
}

.display-mobile {
  display: none;
}

.display-desktop {
  display: block;
}

@media only screen and (max-width: 1000px) {
  .toggle-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 30%;
  }
}

@media only screen and (max-width: 955px) {
  .display-mobile {
    display: block;
  }

  .display-desktop {
    display: none;
  }
}

@media only screen and (min-width: 956px) {
  .display-mobile {
    display: none;
  }

  .display-desktop {
    display: block;
  }
}

@media only screen and (max-width: 800px) {
  .header-text-center {
    margin: 0;
    padding-left: 10px;
  }

  .item.sc-ion-label-ios-h,
  .item .sc-ion-label-ios-h {
    white-space: pre-wrap;
  }

  .bottom-text {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  .current-value-operator {
    width: 10px !important;
    position: absolute;
    top: 210px;
  }

  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 667px;
  }

  .top-text {
    font-size: 14px !important;
    line-height: 16px !important;
  }

  .equals-line {
    border-bottom: 2px solid #000;
  }

  .transaction-info-modal .modal-wrapper {
    max-height: unset !important;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 770px) {
  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 648px;
  }
}

@media only screen and (max-width: 480px) {
  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 673px;
  }
}

@media only screen and (max-width: 400px) {
  .toggle-icon {
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
  }
}

@media only screen and (max-width: 333px) {
  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 695px;
  }
}

@media only screen and (max-width: 325px) {
  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 722px;
  }
}

@media only screen and (max-width: 320px) {
  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 730px;
  }
}

@media only screen and (max-width: 280px) {
  .new-value-operator {
    width: 10px !important;
    position: absolute;
    top: 750px;
  }

  .current-value-operator {
    width: 10px !important;
    position: absolute;
    top: 255px;
  }
}

/* Footer Styling */
.footer {
  background-image: url("../images/ft-bg.jpg");
  background-size: cover;
  background-position: center bottom;
  border-top: solid #002f5f 3px;
  position: relative;
  padding-top: 20px;
  margin: 0;
  bottom: 0;
}

.footer-border-top {
  border-top: 8px #fff solid;
}

.disclaimer {
  font-size: 13px !important;
  line-height: 16px !important;
}

.footer-contact-info {
  font-size: 1em !important;
  line-height: 20px !important;
}

.soc-container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.soc-lnk {
  margin: 0 auto;
  max-width: 30px;
}

.copyright {
  background: #002f5f;
  color: #fff;
  margin: 0;
  text-align: center;
  width: 100%;
}

.copyright p {
  font-size: 12px;
}

.input-mask {
  height: 50px;
  padding-left: 10px;
}

/* not sure this is the best way to overide this css */
.popover-content.sc-ion-popover-md {
  width: max-content;
}

/* disabling radio button on popover picklists */
ion-select-popover ion-list ion-item ion-radio {
  --color: transparent;
  --color-checked: transparent;
}

.sc-ion-modal-ios-h {
  --border-radius: 10px;
  --background: var(--ion-modal-background)
}

.modal-wrapper.sc-ion-modal-md {
  height: 180px;
  min-width: 330px;
  max-width: 1000px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  border-radius: 10px;
  position: fixed;
  top: 40%;
}

.main-page {
  min-height: calc(100% - 250px);
}

.global-header {
  padding-top: env(safe-area-inset-top)
}

.user-agreement-modal .modal-wrapper .ion-page {
  padding-top: env(safe-area-inset-top)
}

.user-agreement-modal .modal-wrapper .ion-page .button {
  margin: 20px
}

.feedback-modal .modal-wrapper .ion-page .button {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
}

.feedback-modal ion-content {
  --background: transparent;
}

.feedback-modal .modal-wrapper.sc-ion-modal-ios {
  border-radius: 0;
}

.edit-modal .modal-wrapper.sc-ion-modal-ios {
  height: 500px;
  width: 500px;
  border: solid #e4e4e4 3px;
}

.edit-investor-modal .modal-wrapper.sc-ion-modal-ios {
  height: 450px;
  width: 700px;
  border: solid #e4e4e4 3px;
}

.edit-participation-content {
  height: 500px;
}

.fund-account-list {
  height: 200px;
  overflow-y: scroll;
}

.fund-account-date-list {
  height: 100px;
  overflow-y: scroll;
}

.edit-investor-button {
  height: 40px;
  width: 85px;
}

.edit-payee-modal-hold .modal-wrapper.sc-ion-modal-ios {
  height: 300px;
  width: 500px;
  border: solid #e4e4e4 3px;
}

.edit-form {
  overflow-y: scroll;
}

.edit-input {
  --padding-start: 10px;
}

.edit-payee-select {
  min-width: 96%;
}

@media only screen and (max-width: 450px) {
  .edit-payee-select {
    min-width: 94.5%;
  }
}

.edit-modal-submit-button {
  height: 30px;
}

/* ToolTip */
.tooltip--text{
  font-size: 12px; 
  font-weight: 600;
}

.fixed-sidebar-button {
  position: fixed;
  bottom: 15px;
  left: 15px;
  height: 60px;
  --padding-end: 30px;
  --padding-start: 30px;
}

.file-upload-modal .modal-wrapper.sc-ion-modal-ios {
  height: 500px;
}

ion-item.FieldError {
  margin-top: 1px;
  --min-height: 30px;
  --padding-start: 0px;
  --border-width: unset;
  color: red;
}

.file-upload-file-form {
  height: 360px;
  overflow-y: scroll;
}

ion-input.FileUpload {
  border: 2px solid lightgray;
  border-radius: unset;
  margin-top: 2px;
  height: 30px;
  font-size: large;
}

div.ModalHeader {
  margin-left: auto;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
}

ion-icon.ModalHeader {
  margin-left: auto;
  margin-right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.file-modal .modal-wrapper.sc-ion-modal-ios {
  height: 95%;
  width: 95%;
}

.logo {
  height: auto;
  width: auto;
}