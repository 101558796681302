
.document-modal .modal-wrapper{
  margin: auto;
  max-height: 100px;
  max-width: 375px;
}

@media(prefers-color-scheme: dark){
    .document-modal{
    --backdrop-opacit:50%;
    --background: rgba(0, 0, 0, 0.8);
    --height: 10%
    }
}

@media(prefers-color-scheme: light){
    .document-modal{
    --backdrop-opacit:50%;
    --background: rgba(255, 255, 255, 0.8);
    --height: 10%
    }
}

.loading {
    font-size: 20px;
    font-weight: bold;
    margin: auto;
    width: 50%;
    padding: 20px;
  }
  
  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 2.5s infinite;
    animation: ellipsis steps(4,end) 2.5s infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }
  
  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }