.auth-container {
    margin: auto;
    margin-top: 10%;
    width: 400px;
    height: 400px;
    position: relative;
}

.auth-center {
  text-align: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 10px;
    padding-bottom: 10px;
    position: absolute;
    z-index: 1;
  }
  
  .auth-animation {
    width: 10px;
    height: 10px;
    background-color: red;
    position: relative;
    animation-name: travel-perimeter;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
  
  @keyframes travel-perimeter {
    0%   {background-color:#4e5056; left:0%; top:0%;}
    25%  {background-color:#4e5056; left:100%; top:0%;}
    50%  {background-color:#4e5056; left:100%; top:38%;}
    75%  {background-color:#4e5056; left:0%; top:38%;}
    100% {background-color:#4e5056; left:0%; top:0%;}
  }
  
  @media only screen and (max-width: 450px) {
    .auth-container {
      width: 200px;
      width: 200px;
    }

    @keyframes travel-perimeter {
      0%   {background-color:#4e5056; left:0%; top:0%;}
      25%  {background-color:#4e5056; left:100%; top:0%;}
      50%  {background-color:#4e5056; left:100%; top:17%;}
      75%  {background-color:#4e5056; left:0%; top:17%;}
      100% {background-color:#4e5056; left:0%; top:0%;}
    }
  }